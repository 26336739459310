var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokens-swap-button"},[_c('v-popover',{attrs:{"popover-inner-class":!_vm.isDisabled
        ? 'hidden'
        : 'bg-catskill-white p-4 text-black text-base rounded font-semibold shadow-drop-xs w-full max-w-xs sm:max-w-sm break-words',"trigger":_vm.isMobile ? 'click' : 'hover',"popover-arrow-class":"hidden"}},[_c('button',{staticClass:"btn tokens-swap-button__connect-button",class:{
        'btn--contrast': !_vm.isDisabled,
        'tokens-swap-button__connect-button--disabled': _vm.isDisabled,
      },attrs:{"disabled":_vm.isDisabled,"type":"button"},on:{"click":_vm.connect}},[_c('span',[_vm._v(" "+_vm._s(_vm.$translate('components.tokensSwapButton.buttonText'))+" ")]),_c('em',{staticClass:"text-2xl fas fa-angle-right",class:{
          'text-aquamarine': !_vm.isDisabled,
          'text-regent-gray': _vm.isDisabled,
        }})]),_c('template',{slot:"popover"},[_vm._v(" "+_vm._s(_vm.popoverTextComputed)+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }














































































































































































































































import {Component, Watch, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {ExpansibleCollection, IResource} from '@simpli/resource-collection'
import {DefaultSchema} from '@/schema/DefaultSchema'
import NftToggleButton from '@/components/misc/GenericToggleButton.vue'
import NftCollectionToggleButton from '@/components/misc/GenericToggleButton.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import TokenImageRender from '@/components/render/TokenImageRender.vue'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import TokenPriceRender from '@/components/render/TokenPriceRender.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import TruncateCellRender from '@/components/render/TruncateCellRender.vue'
import TokensPriceChart from '@/components/tokensprice/TokensPriceChart.vue'
import TokensSwapButton from '@/components/tokensprice/TokensSwapButton.vue'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import {DEFAULT_NETWORK} from '@/libs/blockchain-services/constants/BSNeo3Constants'

@Component({
  components: {
    TokensSwapButton,
    TokensPriceChart,
    TruncateCellRender,
    PercentageProgressRender,
    TokenPriceRender,
    TokenCellRender,
    TokenImageRender,
    GenericToggleButton,
    NftCollectionToggleButton,
    NftToggleButton,
    MoonLoader,
  },
})
export default class TokensPriceScrollableTable extends MixinScreenSize {
  @Prop({type: Boolean, default: false}) showViewAll!: boolean
  @Prop({type: Array, required: true}) items!: TokensTableItem[]
  @Prop({type: String, default: 'MARKET CAP'}) defaultSortKey!: string

  headers = [
    'TOKEN',
    '',
    'PRICE',
    '24H %',
    '7D %',
    'MARKET CAP',
    'TOTAL SUPPLY',
    'LAST 7 DAYS',
  ]

  isLoading = true

  localItems: TokensTableItem[] = []
  currentSortKey = this.defaultSortKey
  currentSortDirection: 'asc' | 'desc' = 'desc'
  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    DEFAULT_NETWORK
  )

  @Watch('items', {immediate: true, deep: true})
  onItemsChanged(newItems: TokensTableItem[]) {
    this.localItems = [...newItems]
  }

  sortValues(value: string) {
    const keyMap: Record<
      string,
      (item: TokensTableItem) => number | string | null
    > = {
      TOKEN: item => item.symbol,
      PRICE: item => item.priceFormatted,
      '24H %': item => item.priceVariationLastDayFormatted,
      '7D %': item => item.priceVariationLastWeekFormatted,
      'MARKET CAP': item => item.marketCapFormatted,
      'TOTAL SUPPLY': item => item.totalSupplyFormatted,
      'LAST 7 DAYS': item => item.priceVariationLastWeekFormatted,
    }

    const keyFn = keyMap[value]
    if (!keyFn) return

    const isSameKey = this.currentSortKey === value

    this.currentSortDirection = isSameKey
      ? this.currentSortDirection === 'asc'
        ? 'desc'
        : 'asc'
      : 'desc'

    this.currentSortKey = value

    this.localItems.sort((a, b) => {
      const valA = keyFn(a) ?? 0
      const valB = keyFn(b) ?? 0

      let result = 0

      if (
        value === 'TOKEN' &&
        typeof valA === 'string' &&
        typeof valB === 'string'
      ) {
        result = valA.localeCompare(valB)
      } else {
        const numA =
          typeof valA === 'string'
            ? parseFloat(valA.replace(/[^\d.-]/g, ''))
            : Number(valA)
        const numB =
          typeof valB === 'string'
            ? parseFloat(valB.replace(/[^\d.-]/g, ''))
            : Number(valB)

        result = numA - numB
      }

      return this.currentSortDirection === 'asc' ? result : -result
    })
  }
}

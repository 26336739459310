

































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {$} from '@/facade'

@Component
export default class TokensSwapButton extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, required: true}) isSwappable!: boolean
  @Prop({type: Boolean, required: true}) isDisabled!: boolean
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem
  @Prop({
    type: String,
    required: false,
    default: $.translate('components.tokensSwapButton.unavailableOnFlamingo'),
  })
  popoverText!: string

  get popoverTextComputed() {
    if (this.isSwappable && this.isDisabled) {
      return $.translate('components.tokensSwapButton.temporarilyUnavailable')
    }

    return $.translate('components.tokensSwapButton.unavailableOnFlamingo')
  }

  async connect() {
    await $.walletAdapter.executeWithConnectedN3Wallet(() =>
      $.modal.open('swapModal', this.token)
    )
  }
}
